/* Position and sizing of burger button */
.bm-burger-button {
  visibility: hidden;
  position: absolute;
  width: 36px;
  height: 30px;
  right: 30px;
  top: 25px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
  border-radius: 25px;
  height: 21;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: black;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  visibility: hidden;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  font-size: 12px;
  position: relative;
  overflow: auto;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: white;
}

/* Wrapper for item list */
.bm-item-list {
  color: black;
  padding: 0.8em;
  height: 100%;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

.bm-overlay {
  background: transparent;
}

/* Styling of overlay */
.bm-overlay {
  visibility: hidden;
}

.menu-item {
  color: vars(--color-purple);
  text-decoration: none;
  padding-bottom: 25px;
  padding-top: 25px;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  font-size: 24px;
}

.green {
  color: var(--color-green);
}

@media (max-width: 1080px) {
  .bm-burger-button {
    visibility: visible;
  }
}

.alert-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.alert {
  background-color: white;
  border: solid 1px #00bbbb;
  padding: 3px;
  width: 644px;
  max-width: 80%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444444;
  font-size: 18;
  font-family: Roboto;
}

.alert p {
  width: 530px;
  text-align: left;
  margin: 5px;
}

.alert p a {
  color: #00bbbb;
}

.alert-image {
  height: 38px;
  margin-right: 10px;
}

@media (max-width: 675px) {
  .alert {
    font-size: 10px;
  }
}

.footer {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  padding-top: 40px;
  width: 100%;
  height: 140px; /* Footer height */
  background-color: white;
}

.footerSection {
  align-items: center;
  justify-content: center;
  width: 60px;
  padding-left: 1%;
  padding-right: 1%;
}

.socialLogo {
  width: 2%;
  border: 1px solid var(--color-background-border);
  border-radius: 50%;
}

.footerText {
  font-family: var(--font-family-fallback);
  padding-top: 1%;
  font-size: 14px;
  color: black;
}

.footer a:link {
  color: var(--color-purple);
}

@media (max-width: 900px) {
  .socialLogo {
    width: 4%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 46px; /* 2px * 24px - 2px */
  height: 26px; /* 24px + 2px */
}

.switch .input {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.switch .input:checked + span {
  background-color: var(--color-green);
}

.switch .input:checked + span::before {
  transform: translateX(20px);
}

.switch .input:focus + span {
  box-shadow: 0 0 1px var(--color-green);
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: var(--color-background-border);
  transition: 0.2s;
  border-radius: 34px;
}

.switch .slider::before {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: 0.2s;
  border-radius: 50%;
}
